import { graphql } from "gatsby";
import React from "react";
import Menu from "../ristorante/menu/index";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-ristorante"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

/**
 * This page is required to support the old existing link "/menu/ristorante",
 * that is hardcoded into physical QR codes printed for the Hotel.
 */
export default function RestaurantMenuForRedirect() {
    return <Menu />;
}

import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import Container from "../../../components/Container";
import Layout from "../../../components/Layout";
import Seo from "../../../components/Seo";
import Separator from "../../../components/Separator";
import restaurantMenu from "../../../constants/restaurantMenu";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-ristorante"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Menu() {
    const {
        i18n: { language },
        t,
    } = useTranslation();

    return (
        <Layout hideQuickLinks={true}>
            <Seo title={t("page-ristorante:theMenu")} />
            <Container>
                <h1>{t("page-ristorante:theMenu")}</h1>
                <small>{t("page-ristorante:menuDisclamer")}</small>
                {restaurantMenu.sections.map((section, sectionIdx) => (
                    <div key={sectionIdx}>
                        <Separator />
                        <span className="text-2xl uppercase flex items-center -ml-3">
                            <MdChevronRight size={32} className="text-gold" />
                            {section.title[language] ?? section.title.it}
                        </span>
                        {section.items.map((item, itemIdx) => (
                            <div key={itemIdx} className="w-full flex mt-1">
                                <div className="grow flex flex-col">
                                    <b>
                                        {item.title[language] ?? item.title.it}
                                    </b>
                                    {item.description !== null ? (
                                        <small>
                                            <i>
                                                {item.description[language] ??
                                                    item.description.it}
                                            </i>
                                        </small>
                                    ) : null}
                                </div>
                                <div className="pl-4">{item.amount}€</div>
                            </div>
                        ))}
                    </div>
                ))}
            </Container>
        </Layout>
    );
}

// TODO: translate it in english after a preliminary review of the content
const restaurantMenu = {
    sections: [
        {
            items: [
                {
                    title: {
                        it: "Pane, coperto e servizio",
                    },
                    description: null,
                    amount: 2,
                },
            ],
            title: {
                it: "Coperto e servizio",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Fritto misto italiano",
                    },
                    description: {
                        it: "Verdure pastellate, anelli di cipolla, supplì di riso, involtino primavera, mozzarella in carrozza, crocchè di patate, jalapeno al cheddar",
                    },
                    amount: 9,
                },
                {
                    title: {
                        it: "Gran tagliere di salumi, formaggi e bruschette",
                    },
                    description: {
                        it: "Salumi di Norcia, formaggi locali , bruschette miste, torta al testo e fantasie dello chef",
                    },
                    amount: 13,
                },
                {
                    title: {
                        it: "Strapazzata di uova al tartufo nero con crostini di pane all’olio e.v.o.",
                    },
                    description: null,
                    amount: 10,
                },
            ],
            title: {
                it: "Antipasti",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Pappardelle del Subasio",
                    },
                    description: {
                        it: "Ragù bianco di chianina, tartufo, pecorino del Subasio",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Strangozzi alla Norcina",
                    },
                    description: {
                        it: "Salsiccia norcina, funghi, tartufo nero, panna e pecorino",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Ravioliacci ai funghi porcini all’Assisana",
                    },
                    description: {
                        it: "Rustica di basilico, fiocchi di pomodoro e scaglie di grana",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Gnocchi di patate Colfiorito alla Cannarese",
                    },
                    description: {
                        it: "Gnocchi con patata rossa di Colfiorito, crema di zafferano e cipolla di Cannara",
                    },
                    amount: 11,
                },
                {
                    title: {
                        it: "Spaghettone rustico alla carbonara con tartufo nero",
                    },
                    description: {
                        it: "Guanciale di maiale, uova, pecorino, pepe, tartufo",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Paccheri all’Amatriciana",
                    },
                    description: {
                        it: "Guanciale di maiale, pomodoro, pecorino, cipolla",
                    },
                    amount: 9,
                },
                {
                    title: {
                        it: "Spaghettoni Cacio & Pepe",
                    },
                    description: null,
                    amount: 9,
                },
            ],
            title: {
                it: "Primi",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Maialino in porchetta al finocchietto selvatico",
                    },
                    description: null,
                    amount: 10,
                },
                {
                    title: {
                        it: "Cinghiale alla cacciatora con torta al testo",
                    },
                    description: null,
                    amount: 12,
                },
                {
                    title: {
                        it: "Tagliata di vitello ai funghi",
                    },
                    description: null,
                    amount: 15,
                },
                {
                    title: {
                        it: "Tagliata di vitello al tartufo nero",
                    },
                    description: null,
                    amount: 17,
                },
                {
                    title: {
                        it: "Tagliata di pollo alla rustica",
                    },
                    description: {
                        it: "Rucola, pomodorini, scaglie di grana con salsa al radicchio e cipolla di Cannara",
                    },
                    amount: 13,
                },
                {
                    title: {
                        it: "Filetto di maiale della Mirella",
                    },
                    description: {
                        it: "Pancetta arrosto, verdure di campo, pinoli, glassa di aceto, cipolla croccante",
                    },
                    amount: 13,
                },
                {
                    title: {
                        it: "Scaloppine di vitellino ai funghi",
                    },
                    description: {
                        it: "In alternativa: al limone",
                    },
                    amount: 11,
                },
            ],
            title: {
                it: "Secondi",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Rustico",
                    },
                    description: {
                        it: "Cotolettata di pollo, insalata, pomodoro, salse. (servito con porzione di patate fritte)",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Topolino",
                    },
                    description: {
                        it: "Hamburgher di manzo da 180 gr, ketchup e maionese. (servito con porzione di patate fritte)",
                    },
                    amount: 10,
                },
                {
                    title: {
                        it: "Subasio",
                    },
                    description: {
                        it: "Hamburgher di manzo da 180 gr, cheddar, insalata, cipolla croccante e salse. (servito con porzione di patate fritte)",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Angelano",
                    },
                    description: {
                        it: "Hamburgher di manzo da 180 gr, verdure grigliate, caciotta fusa e salse. (servito con porzione di patate fritte)",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Hotel Moderno",
                    },
                    description: {
                        it: "Hamburgher di manzo da 180 gr, bacon croccante, verdure di campo, aceto glassato, grana e salse. (servito con porzione di patate fritte)",
                    },
                    amount: 12,
                },
            ],
            title: {
                it: "Hamburger",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Torta al testo con prosciutto di Norcia",
                    },
                    description: {
                        it: "La porzione viene accompagnata da un’insalata mista",
                    },
                    amount: 11,
                },
                {
                    title: {
                        it: "Torta al testo con prosciutto crudo e pecorino di Norcia",
                    },
                    description: {
                        it: "La porzione viene accompagnata da un’insalata mista",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Torta al testo con salsiccia alla brace ed erba ripassata",
                    },
                    description: {
                        it: "La porzione viene accompagnata da un’insalata mista",
                    },
                    amount: 12,
                },
                {
                    title: {
                        it: "Torta al testo con barbozzo di maiale di Norcia",
                    },
                    description: {
                        it: "La porzione viene accompagnata da un’insalata mista",
                    },
                    amount: 11,
                },
            ],
            title: {
                it: "Torta al testo",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Verdure miste grigliate del giorno",
                    },
                    description: null,
                    amount: 6,
                },
                {
                    title: {
                        it: "Patate fritte",
                    },
                    description: null,
                    amount: 5,
                },
                {
                    title: {
                        it: "Insalata mista",
                    },
                    description: null,
                    amount: 5,
                },
                {
                    title: {
                        it: "Verdura di campo ripassata all’aglio, olio evo e peperoncino",
                    },
                    description: null,
                    amount: 5,
                },
                {
                    title: {
                        it: "Pomodori con cipolla, sedano e basilico in insalata",
                    },
                    description: null,
                    amount: 5,
                },
                {
                    title: {
                        it: "Panzanella Umbra",
                    },
                    description: null,
                    amount: 6,
                },
            ],
            title: {
                it: "Contorni",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Gelato di creme in coppa",
                    },
                    description: null,
                    amount: 5,
                },
                {
                    title: {
                        it: "Cheesecake ai frutti di bosco",
                    },
                    description: null,
                    amount: 5,
                },
                {
                    title: {
                        it: "Semifreddo alle Pesche e Frutto della Passione",
                    },
                    description: null,
                    amount: 5,
                },
                {
                    title: {
                        it: "Pralina Perugina",
                    },
                    description: null,
                    amount: 5,
                },
                {
                    title: {
                        it: "Frutta di stagione",
                    },
                    description: null,
                    amount: 5,
                },
            ],
            title: {
                it: "Dessert",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Acqua minerale naturale o gassata 1lt",
                    },
                    description: null,
                    amount: 2.5,
                },
                {
                    title: {
                        it: "Acqua minerale naturale o gassata 0.5lt",
                    },
                    description: null,
                    amount: 1.5,
                },
                {
                    title: {
                        it: "Sangiovese o Trebbiano dell’Umbria dell'Umbria in caraffa 1lt",
                    },
                    description: {
                        it: "In caraffa",
                    },
                    amount: 7,
                },
                {
                    title: {
                        it: "Sangiovese o Trebbiano dell’Umbria dell'Umbria in caraffa 50cl",
                    },
                    description: {
                        it: "In caraffa",
                    },
                    amount: 5,
                },
                {
                    title: {
                        it: "Sangiovese o Trebbiano dell’Umbria dell'Umbria in caraffa 25cl",
                    },
                    description: {
                        it: "In caraffa",
                    },
                    amount: 3.5,
                },
                {
                    title: {
                        it: "Birra Peroni 66cl",
                    },
                    description: null,
                    amount: 4.5,
                },
                {
                    title: {
                        it: "Birra Peroni 33cl",
                    },
                    description: null,
                    amount: 3,
                },
                {
                    title: {
                        it: "Birra Heineken 66cl",
                    },
                    description: null,
                    amount: 4.5,
                },
                {
                    title: {
                        it: "Bibite gassate in lattina 33cl",
                    },
                    description: null,
                    amount: 2.5,
                },
                {
                    title: {
                        it: "Caffè espresso Borbone",
                    },
                    description: null,
                    amount: 1.5,
                },
                {
                    title: {
                        it: "Cappuccino",
                    },
                    description: null,
                    amount: 2.5,
                },
                {
                    title: {
                        it: "Digestivi",
                    },
                    description: null,
                    amount: 3.5,
                },
            ],
            title: {
                it: "Bevande",
            },
        },
        {
            items: [
                {
                    title: {
                        it: "Rosso di Torgiano «Rubesco» DOC, cantina Lungarotti, 750ml",
                    },
                    description: {
                        it: "Sangiovese e Colorino. Rosso dal corpo equilibrato, ha bel colore rubino e profumi tipicamente speziati con decisi richiami al pepe e al tabacco dolce e sottofondo di confettura rossa e violetta.",
                    },
                    amount: 15,
                },
                {
                    title: {
                        it: "Rosso dell'Umbria IGT Sangiovese (da agricoltura sociale), cantina Di Filippo, 750ml",
                    },
                    description: {
                        it: "Vino da agricoltura sociale in collaborazione con l'azienda agricola sociale La Semente, impresa sociale impegnata nel sostegno delle persone con autismo.",
                    },
                    amount: 15,
                },
                {
                    title: {
                        it: "Montefalco Rosso DOC, cantina Arnaldo Caprai, 750ml",
                    },
                    description: {
                        it: "70% Sangiovese, 15% Sagrantino, 15% Merlot. 12 mesi di barrique e almeno 4 mesi in bottiglia. Fiori di campo, frutti rossi e un tocco di noce moscata. In bocca entra secco, fresco, giusta la morbidezza in contrapposizione ai tannini.",
                    },
                    amount: 15,
                },
                {
                    title: {
                        it: "Montefalco Rosso DOC BIO, cantina Antonelli, 750ml",
                    },
                    description: {
                        it: "70% Sangiovese, Sagrantino 15%, altri vitigni centro italia 15%. Colore rosso rubino. Profumo intenso e fruttato, ricorda ciliegia e frutti di bosco. Al palato risulta secco, equilibrato e di buona struttura. La freschezza del Sangiovese gli conferisce un’ottima bevibilità.",
                    },
                    amount: 16,
                },
                {
                    title: {
                        it: "Villa Barbi Rosso, cantina Decugnano dei Barbi, 750ml",
                    },
                    description: {
                        it: "Sangiovese 34%, Cabernet Sauvignon 33%, Merlot 33%. All'olfatto aromi ampi ed intensi, riproposti in bocca con gusto pieno e generoso, strutturato e ricco di corpo.",
                    },
                    amount: 18,
                },
                {
                    title: {
                        it: "Villa Fidelia Rosso (IGP), cantina Sportoletti, 750ml",
                    },
                    description: {
                        it: "Merlot 70%, Cabernet Sauvignon 20%, Cabernet Franc 10%. Di profondo colore rosso scuro, con profumi evoluti che richiamano confettura di more, tabacco, spezie, liquirizia e cioccolato.",
                    },
                    amount: 20,
                },
                {
                    title: {
                        it: "Il Roscialesco (IGT), cantina Paolucci, 750ml",
                    },
                    description: {
                        it: "Cuore Rubino con eleganti viraggi violacei. Libera al naso ricami di rosa canina su un fondo scuro di cacao in polvere. Decisa la freschezza all’assaggio, leggiadro nel suo genere agile e speziato. Elegante il tannino, chiude con buona persistenza su toni di cioccolato e lamponi.",
                    },
                    amount: 20,
                },
                {
                    title: {
                        it: "Rosso dei Colli Perugini DOC «l’Arringatore», cantina Goretti, 750ml",
                    },
                    description: {
                        it: "60% Sangiovese, 30% Merlot, 10% Ciliegiolo. Pieno, di buona struttura con tannini morbidi ben integrati. Aromatico, con note eleganti e raffinate. Sentori di frutta rossa matura. Note speziate di cuoio, tabacco e liquirizia.",
                    },
                    amount: 22,
                },
                {
                    title: {
                        it: "Montefalco Rosso DOC, cantina Milziade Antano, 750ml",
                    },
                    description: {
                        it: "Rosso rubino scuro, al naso esprime note intense di frutta rossa matura impreziosita da note floreali, di viola passita. Cannella, timo, pepe nero sono sentori che aprono ad un assaggio lungo e perfettamente equilibrato in ogni sua componente. Lungo e vibrante, fresco e tannico, chiude con un finale molto pulito, sul frutto.",
                    },
                    amount: 22,
                },
                {
                    title: {
                        it: "Bianco di Torgiano DOC «Torre di Giano», cantina Lungarotti, 750ml",
                    },
                    description: {
                        it: "Bianco fresco, paglierino con riflessi verdognoli, fruttato, con profumi lunghi e intensi dai netti richiami agrumati, fiori bianchi e macedonia fresca; asciutto, di buona struttura, ha evoluzione fragrante con delicata vena acidula, e lungo retrogusto.",
                    },
                    amount: 15,
                },
                {
                    title: {
                        it: "Grechetto dei Colli Martani «Grecante» DOC, cantina Arnaldo Caprai, 750ml",
                    },
                    description: {
                        it: "100% Grechetto. Intensi profumi di frutta esotica e pesca gialla, note floreali di fresia e biancospino e per finire una gradevole nota gessosa.",
                    },
                    amount: 15,
                },
                {
                    title: {
                        it: "Trebbiano Spoletino DOC, cantina Perticaia, 750ml",
                    },
                    description: {
                        it: "100% Trebbiano Spoletino.  Ampio, con piacevole freschezza e sapidità. La media acidità è sorretta da una dolcezza di fondo.",
                    },
                    amount: 20,
                },
            ],
            title: {
                it: "Selezione di vini Umbri",
            },
        },
    ],
};

export default restaurantMenu;
